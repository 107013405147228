@import "@angular/material/prebuilt-themes/indigo-pink.css";

:root {
    --ion-color-primary: var(--light-main);
    --ion-color-secondary: var(--dark-main);
    --ion-color-danger: #eb445a;
    --green: #7ddda4 !important;

    --gradebook-green: #BBF7E9;
    --gradebook-yellow: #FEEAC4;
    --gradebook-orange: #fedbc4;
    --gradebook-red: #FFE0DD;
    --gradebook-gray: #E0E4E7;

    --gradebook-green-text: #009978;
    --gradebook-yellow-text: #a86f02;
    --gradebook-orange-text: #563902;
    --gradebook-red-text: #EB445A;
    --gradebook-gray-text: #3A4249;

    --gradebook-green-status: #009978;
    --gradebook-yellow-status: #FFCD58;
    --gradebook-gray-status: #E0E4E7;
    --gradebook-red-status: #fa1d20;
    --gradebook-orange-status: #FF9636;

    --gradebook-header-row: #e7ecf5;
    --gradebook-header-darker: #d4dce9;
    --gradebook-header-lighter: #e5e5e5;
    --gradebook-table-hover: #cde1f2;

    --gradebook-table-text: #152942;
    --gradebook-light-blue: #26ace0;

    --gradebook-menu-hover: #0f6396;
    --gradebook-toolbar-background: #006eb7;

    --gradebook-light-gray: #b9b9b9;

    --gradebook-feedback-circle: #8393a0;

    --gradebook-table-cell: #f5f5f5;

    --gradebook-platform-color: #fafcff;

    //   New lite colors
    --new-lite-primary: #2984D8;

    // Rubric colors
    --rubric-button-gray-background: #ADADAD;
    --rubric-table-header-color: #667085;
    --rubric-table-header-background: #F9FAFB;
    --rubric-table-border: #CFCFCF;
    --rubric-blue: #2984D8;
    --rubric-red: #EB445A;
    --rubric-green: #009978;
    --rubric-yellow: #FFB00E;
}

p, span, h1, h2, h3, h4, h5, h6 {
  cursor: default;
}

button p, button span, button h1, button h2, button h3, button h4, button h5, button h6,
.header p, .header span, .header h1, .header h2, .header h3, .header h4, .header h5, .header h6,
.content p, .content span, .content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  cursor: pointer;
}

button:disabled, button:disabled p, button:disabled span, button:disabled h1, button:disabled h2, button:disabled h3, button:disabled h4, button:disabled h5, button:disabled h6 {
  cursor: default;
}

.csv-alert {
  --max-width: 400px;
  --width: 400px;
}
.modal-class {
  --max-height: 50vh;
  --width: 100%;
  margin-top: 50vh;
  align-items: flex-end;
}
.modal-class-landscape {
  --height: 100%;
  --width: 50vw;
  margin-left: 50vw;
  justify-content: flex-end;
}
.modal-CA-class {
  --max-height: 91.5vh;
  --max-width: 90vw;
  --min-width: 300px;
  --width: 50%;
  margin-top: 8.5vh;
  align-items: flex-end;
  --backdrop-opacity: 0.7;
}
.modal-CA-class-landscape {
  --height: 100%;
  --width: 50vw;
  margin-right: 50vw;
  justify-content: flex-end;
}
.modal-Detail-class {
  --max-height: 91.5vh;
  --max-width: 80vw;
  --min-width: 300px;
  --width: 100%;
  margin-top: 8.5vh;
  margin-left: 20vw;
  align-items: flex-end;
  --backdrop-opacity: 0.7;
}
.modal-Detail-class-landscape {
  --max-height: 81.5vh;
  margin-top: 18.5vh;
  --height: 100%;
  --width: 65vw;
  margin-left: 35vw;
  justify-content: flex-end;
}
.modal-Chart-class {
  --height: 100vh;
  --width: 100%;
  align-items: flex-end;
  --backdrop-opacity: 0.7;
}
.modal-wrapper.sc-ion-modal-ios {
  height: 100% !important;
  margin-right: auto;
}
.expand-modal,
.prompt-media-modal {
  --height: 100vh;
  --width: 50vw;
  --background: #f8f8f8;
}

.expand-modal {
  justify-content: flex-end;
  margin-left: 50vw;
}

.prompt-media-modal {
  justify-content: flex-start;
  margin-right: 50vw;
}

.ap-full-screen-prompt-media {
    --height: 90vh;
    --background: #f8f8f8;
    --width: 100%;
}

.mobile-expand-modal,
.mobile-prompt-media {
  --height: 50vh;
  --width: 100%;
  --background: #f8f8f8;
}

.mobile-expand-modal {
  align-items: flex-end;
  margin-top: 50vh;
}

.mobile-prompt-media {
  margin-bottom: 50vh;
  align-items: flex-start;
}

.lockable-popup {
  --max-width: 420px !important;
}

.add-class-modal {
  --height: 320px;
  --max-width: 320px;
}

.whitespace-class {
  white-space: pre-line;
}

.keymanweb-font {
  font-family: "Fredoka One", cursive;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  text-align: center;
  color: #404040;
  font-family: "Muli", sans-serif;
  font-size: 17px;
  background-color: white;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.app-margin {
  padding-top: 20px;
  background-color: #f8f8f8;
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}

app-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

#page {
  margin: 10px auto;
  text-align: center;
  min-height: calc(100vh - 94px - 78px - 8px);
  background-color: white;
  border-radius: 4px;
  max-width: 986px;
  box-shadow: none;
}

.new-rubrics-popup {
  text-align: center;
  overflow: auto !important;
}

.new-rubrics-popup .alert-wrapper {
  width: fit-content;
  min-width: 30%;
  max-width: 94%;
}

.new-rubrics-popup .alert-message {
  max-height: 1000px;
  display: flex;
  flex-direction: column;
}

.r-name {
  font-size: 16px;
  font-weight: 700;
  white-space: wrap;
}

.r-description {
  font-size: 14px;
  font-weight: 400;
  color: slategrey;
  white-space: wrap;
  max-height: 100px;
  overflow-y: auto;
}

.r-description2 {
  font-size: 12px;
  font-weight: 400;
  color: slategrey;
  white-space: wrap;
  max-height: 100px;
  overflow-y: auto;
}

.r-table-container {
  padding: 0px;
  border-radius: 0px;
  overflow-x: auto;
  overflow-y: auto;
  color: #000;
}

.new-rubrics-popup table {
  width: 100%;
  white-space: nowrap;
  position: relative;
  border-collapse: separate;
}

.new-rubrics-popup th:first-child,
.new-rubrics-popup tr > td:first-child {
  position: sticky;
  left: 0;
  z-index: 15;
  background: white;
}

.new-rubrics-popup th, .new-rubrics-popup td {
  border: 1px solid var(--rubric-table-border);
  // Don't remove height 1px it's a hack to get the ratings have a height of 100% of the parent
  height: 1px;
}

.r-table-container p {
  padding: 0px;
  margin-bottom: 0px;
}

.r-ratings {
  display: flex;
  justify-content: center;
  height: 100%;
}

.r-ratings > * + * {
  border-left: 1px solid var(--rubric-table-border);
}

.r-ratings > * {
  flex-basis: 100%;
}

.r-rating {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.r-rating, .r-criteria {
  padding: 5px;
}

.r-rating2 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(125, 221, 164, 0.4);
}

.r-rating2, .r-criteria {
  padding: 5px;
}

.popup-timer-text {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0;
  color: var(--light-main);
  cursor: default;
}

.popup-timer-text span {
  color: initial;
}

.popup-timer-text .material-icons {
  color: var(--ion-color-danger);
}

.popup-timer-text .popup-text {
  margin-right: 5px;
}

.popup-ap-simulated-text {
    margin-bottom: 5px;
}

.lockable-popup-message {
  font-weight: bold;
  color: var(--ion-color-danger);
}
ion-button:hover {
  border: none !important;
}
h1 {
  font-family: "Fredoka One", cursive;
  font-weight: 500;
  font-size: 35px;
  color: var(--dark-main);
}

.callout {
  font-family: "Fredoka One", cursive;
  color: var(--light-main);
}

.no-scores {
  font-family: "Fredoka One", cursive;
  color: var(--ion-color-danger);
}

.congrats-popup {
  --min-width: 50%;
}

.congrats-popup .alert-wrapper .alert-head h2 {
  font-size: 1.5rem;
  color: var(--green);
}

.popup-congrats-message {
  font-size: 1.22rem;
}

.popup-assessment-name {
  color: var(--light-main);
}

.congrats-back-button span {
  color: var(--ion-color-danger);
}

.green-header {
  background: var(--dark-main);
  padding: 10px 0;
  cursor: default;
}

.green-header h1 {
  color: white;
  margin: 0;
  font-size: 20px;
}

.green-header h3 {
  margin: 0;
  color: white;
  font-weight: bold;
  font-family: var(--ion-font-family, inherit);
  font-size: 20px;
}

input:not([type="range"]),
select {
  border-radius: 6px;
  border: 2px solid #beccd4;
}
a {
  text-decoration: none;
  color: var(--light-main);
}
a:focus {
  box-shadow: 0 0 15px #01c853;
}
.mat-card-header-text {
  margin: 0px !important;
}
app-question-list,
app-assessment-list {
  display: block;
}
h1 {
  margin-bottom: 8px;
}
h2 {
  margin-bottom: 8px;
}
p {
  margin-bottom: 16px;
}
button {
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  cursor: pointer;
  font-family: "Muli", sans-serif;
  border: none !important;
  background: none;
}

button:focus,
.button:focus {
  box-shadow: 0 0 15px #01c853;
}
button.play,
button.play:disabled,
button.play:hover,
button.play:disabled:hover {
  background-image: url(./img/SVG/btn-play-other-full.svg) !important;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  padding-left: 49px;
  padding-right: 6px;
  margin-top: 6px;
  margin-left: 30px;
}
button.playInactive,
button.playInactive:disabled,
button.playInactive:hover,
button.playInactive:disabled:hover {
  background-image: url(./img/SVG/btn-play-grey.svg) !important;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  padding-left: 49px;
  padding-right: 6px;
  margin-top: 6px;
  margin-left: 30px;
  cursor: default;
}
button.mobilePlay,
button.mobilePlay:disabled,
button.mobilePlay:hover,
button.mobilePlay:disabled:hover {
  background-image: url(./img/SVG/btn-play-other-full.svg) !important;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  padding-top: 0px;
  padding-left: 21px;
  padding-right: 0px;
  margin-top: 4px;
  margin-left: 25px;
  margin-bottom: 2px;
}
button.mobilePlayInactive,
button.mobilePlayInactive:disabled,
button.mobilePlayInactive:hover,
button.mobilePlayInactive:disabled:hover {
  background-image: url(./img/SVG/btn-play-grey.svg) !important;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  padding-top: 0px;
  padding-left: 21px;
  padding-right: 0px;
  margin-top: 4px;
  margin-left: 25px;
  margin-bottom: 2px;
  cursor: default;
}
button.playMine,
button.playMine:disabled,
button.playMine:hover,
button.playMine:disabled:hover {
  background-image: url(./img/SVG/btn-play-mine-full.svg) !important;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  padding-left: 49px;
  padding-right: 6px;
  margin-top: 6px;
  margin-left: 30px;
}
button.playMineInactive,
button.playMineInactive:disabled,
button.playMineInactive:hover,
button.playMineInactive:disabled:hover {
  background-image: url(./img/SVG/btn-play-grey.svg) !important;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  padding-left: 49px;
  padding-right: 6px;
  margin-top: 6px;
  margin-left: 30px;
  cursor: default;
}
button.mobilePlayMine,
button.mobilePlayMine:disabled,
button.mobilePlayMine:hover,
button.mobilePlayMine:disabled:hover {
  background-image: url(./img/SVG/btn-play-mine-full.svg) !important;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  padding-top: 0px;
  padding-left: 21px;
  padding-right: 0px;
  margin-top: 4px;
  margin-left: 25px;
  margin-bottom: 2px;
}
button.mobilePlayMineInactive,
button.mobilePlayMineInactive:disabled,
button.mobilePlayMineInactive:hover,
button.mobilePlayMineInactive:disabled:hover {
  background-image: url(./img/SVG/btn-play-grey.svg) !important;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  padding-top: 0px;
  padding-left: 21px;
  padding-right: 0px;
  margin-top: 4px;
  margin-left: 25px;
  margin-bottom: 2px;
  cursor: default;
}
button.play:disabled,
button.playInactive:disabled,
button.mobilePlay:disabled,
button.mobilePlayInactive:disabled,
button.playMine:disabled,
button.playMineInactive:disabled,
button.mobilePlayMine:disabled,
button.mobilePlayMineInactive:disabled,
button.pause:disabled,
button.pauseInactive:disabled,
button.mobilePause:disabled,
button.mobilePauseInactive:disabled,
button.pauseMine:disabled,
button.pauseMineInactive:disabled,
button.mobilePauseMine:disabled,
button.mobilePauseMineInactive:disabled {
  cursor: default;
}
button.pause,
button.pause:disabled,
button.pause:hover,
button.pause:disabled:hover {
  background-image: url(./img/SVG/btn-pause-other-full.svg) !important;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  padding-left: 49px;
  padding-right: 6px;
  margin-top: 6px;
  margin-left: 30px;
  cursor: pointer;
}
button.pauseInactive,
button.pauseInactive:disabled,
button.pauseInactive:hover,
button.pauseInactive:disabled:hover {
  background-image: url(./img/SVG/btn-pause-grey.svg) !important;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  padding-left: 49px;
  padding-right: 6px;
  margin-top: 6px;
  margin-left: 30px;
}
button.mobilePause,
button.mobilePause:disabled,
button.mobilePause:hover,
button.mobilePause:disabled:hover {
  background-image: url(./img/SVG/btn-pause-other-full.svg) !important;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  padding-top: 0px;
  padding-left: 21px;
  padding-right: 0px;
  margin-top: 4px;
  margin-left: 25px;
  margin-bottom: 2px;
  cursor: pointer;
}
button.mobilePauseInactive,
button.mobilePauseInactive:disabled,
button.mobilePauseInactive:hover,
button.mobilePauseInactive:disabled:hover {
  background-image: url(./img/SVG/btn-pause-grey.svg) !important;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  padding-top: 0px;
  padding-left: 21px;
  padding-right: 0px;
  margin-top: 4px;
  margin-left: 25px;
  margin-bottom: 2px;
}
button.pauseMine,
button.pauseMine:disabled,
button.pauseMine:hover,
button.pauseMine:disabled:hover {
  background-image: url(./img/SVG/btn-pause.svg) !important;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  padding-left: 49px;
  padding-right: 6px;
  margin-top: 6px;
  margin-left: 30px;
  cursor: pointer;
}
button.pauseMineInactive,
button.pauseMineInactive:disabled,
button.pauseMineInactive:hover,
button.pauseMineInactive:disabled:hover {
  background-image: url(./img/SVG/btn-pause-grey.svg) !important;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  padding-left: 49px;
  padding-right: 6px;
  margin-top: 6px;
  margin-left: 30px;
}
button.mobilePauseMine,
button.mobilePauseMine:disabled,
button.mobilePauseMine:hover,
button.mobilePauseMine:disabled:hover {
  background-image: url(./img/SVG/btn-pause.svg) !important;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  padding-top: 0px;
  padding-left: 21px;
  padding-right: 0px;
  margin-top: 4px;
  margin-left: 25px;
  margin-bottom: 2px;
  cursor: pointer;
}
button.mobilePauseMineInactive,
button.mobilePauseMineInactive:disabled,
button.mobilePauseMineInactive:hover,
button.mobilePauseMineInactive:disabled:hover {
  background-image: url(./img/SVG/btn-pause-grey.svg) !important;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  padding-top: 0px;
  padding-left: 21px;
  padding-right: 0px;
  margin-top: 4px;
  margin-left: 25px;
  margin-bottom: 2px;
}
.google-sign-in {
  background-color: white;
  border: 1px solid #ccc;
  padding: 9px 16px;
  border-radius: 10px;
  color: #333;
  font-size: 19px;
  display: flex;
  align-items: center;
  margin: 0px auto;
}
.google-sign-in > div {
  display: flex;
  align-items: center;
}
.google-sign-in svg {
  width: 18px;
  height: 18px;
  margin-right: 12px;
}
.glogin {
  display: flex;
  align-items: center;
  width: 200px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
}
.glogin:hover {
  box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
}
.glogin svg {
  padding: 8px;
}
.glogin span {
  font-family: Roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.21px;
  color: #757575;
}
#apple-sign-in {
  background: #000000;
  color: white;
  border: 2px solid #000 !important;
  border-radius: 10px;
  padding: 9px 16px;
  font-size: 19px;
  display: flex;
  align-items: center;
  margin: 0px auto;
}
#apple-sign-in:hover {
  background: white;
  color: black !important;
}
#apple-sign-in svg {
  margin-right: 12px;
  width: 22px;
  height: 22px;
}
#apple-sign-in:hover .appleLogo {
  fill: #000;
}
.appleLogo {
  fill: #fff;
}
#clever-sign-in {
  background: #446cf2;
  color: #ffffff;
  border: 2px solid #ffffff !important;
  border-radius: 10px;
  padding: 9px 16px;
  font-size: 19px;
  display: flex;
  align-items: center;
  margin: 0px auto;
}
#clever-sign-in:hover {
  background: white;
  color: #446cf2 !important;
  border: 2px solid #446cf2 !important;
}
#clever-sign-in .clever-icon {
  margin-right: 12px;
  font-size: 25px;
  font-family: "Open Sans", sans-serif;
}
#lms-sign-in {
  font-weight: bold;
  background: var(--gradebook-green-text);
  border: 2px solid transparent !important;
  color: white;
  border-radius: 10px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  margin: 0px auto;
}
#lms-sign-in:hover {
    background: white;
    color: var(--gradebook-green-text);
    border: 2px solid var(--gradebook-green-text) !important;
}
label {
  display: block;
  margin-bottom: 16px;
}
.overlay-dark-background {
  background: rgba(0, 0, 0, 0.8);
}
.assessment {
  cursor: pointer;
}
/* Class list css */
.mat-grid-tile {
  transition: all 300ms ease-out 50ms;
}
.mat-grid-tile {
  cursor: pointer;
}
.mat-grid-tile-footer {
  background: var(--light-main) !important;
}
.tile-title {
  margin: auto !important;
  padding: unset !important;
  font-size: 1.2em !important;
  font-weight: 500 !important;
}
.class-list-item {
  background: #f8f8f8;
  border-radius: 20px;
}
.class-list-image {
  width: 50%;
  height: 50%;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
.mat-grid-tile:hover .class-list-image {
  width: 70%;
  height: 70%;
}
.class-list-image-mobile {
  height: 30px;
  width: 30px;
  object-fit: cover;
  position: absolute;
  bottom: 20%;
  left: 5px;
}
.class-list-title-mobile {
  padding: 0;
  margin: auto 0px auto 30px;
  font-size: 13px;
  overflow-wrap: break-word;
}
.class-view-toggle-icon {
  color: var(--dark-main);
  font-size: 45px;
  float: right;
  cursor: pointer;
  width: fit-content !important;
  height: fit-content !important;
}
/* END Class list css */
/* List items */
.list-item {
  background: #f8f8f8;
  width: 95%;
  margin: 20px auto;
  cursor: pointer;

  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.list-item:hover .class-list-title {
  color: var(--light-main);
  font-weight: 600;
}
/* END List items */
.username {
  margin: 32px;
  font-size: 1.5em;
  font-weight: bold;
  color: var(--dark-main);
}
toolbar {
  display: block;
}
.question-attachment {
  margin: 0px auto;
  width: 100%;
}
.popup {
  min-width: 200px;
  text-align: center;
}
.header-bar {
  padding: 5px 20px;
  font-size: 16px;
  background-color: #eff2f4;
  box-shadow: none;
}
.header-bar-mobile {
  z-index: 3;
  padding: 10px 0 7px;
  background-color: #eff2f4;
  box-shadow: none;
}
.header-bar > div {
  margin: auto 10px;
}

.header-bar-mobile > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto 10px;
}

.header-bar nav > a,
.header-bar nav > div {
  color: #404040;
  text-decoration: none;
  margin: 0 0 0 32px;
  display: inline;
  cursor: pointer;
}
.header-bar nav > a:hover {
  color: rgb(52, 114, 139);
}

.dropdown-container {
  position: relative;
}
.nav-dropdown {
  z-index: 10;
  position: relative;
  padding-right: 15px;
  background: none;
  border: none !important;
  color: black;
  font-family: "Muli", sans-serif;
  text-transform: unset;
}

.nav-dropdown:hover {
  background: none;
  color: none;
  border: none;
}
.sidenav {
  min-height: 100%;
  max-width: 65%;
  background-color: #eff2f4;
  position: absolute;
  z-index: 2;
  margin-top: -8px;
}
.sidenav .nav-link {
  padding: 7px;
  text-decoration: none;
  font-size: 17px;
  color: var(--light-main);
  display: block;
  cursor: pointer;
  overflow-wrap: break-word;
}
.account-name {
  align-items: center;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
  color: var(--dark-main);
}
.popup-options {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  right: 0px;
  top: 32px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.8em;
  width: 175px;
  z-index: 10;
}
.popup-options > * {
  padding: 10px 5px;
  width: 100%;
  text-align: left;
}
.popup-options > *:hover {
  background-color: #ddd;
}
mat-card {
  margin: 16px 0;
}
p {
  padding: 16px;
}
.submission {
  margin: 20px 0px;
  display: inline !important;
}
.mobile-wrap {
  position: relative;
}
.wrap {
  display: flex;
  flex-wrap: wrap;
}
.wrap mat-card {
  margin: 16px;
}
mat-card.attempt {
  display: flex;
  cursor: pointer;
}
div.attempt-locked {
  background-color: rgb(255, 213, 213);
  cursor: not-allowed;
}
div.attempt-submitted {
  background-color: #eee;
}
div.attempt-graded {
  background-color: rgba(205, 252, 221, 0.39);
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--light-main);
}
.loading-indicator {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.loading-indicator:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.ap-icon {
  max-width: 24px;
  max-height: 24px;
  vertical-align: -webkit-baseline-middle;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon img {
  max-width: 100px;
  max-height: 100px;
}

marketing-footer {
  background-color: #f8f8f8;
  display: block;
}

.warning {
  background-color: firebrick;
  color: white;
  padding: 32px;
  border-radius: 16px;
  margin: 16px auto;
}

.error {
  color: firebrick;
  font-weight: bolder;
  font-size: 1.1em;
}

.account-creation-step img {
  width: 20px;
  vertical-align: middle;
}

.account-creation-step span {
  vertical-align: middle;
}

.step-started {
  font-weight: 600;
  color: #dfdc27;
}

.step-done {
  margin-left: 20px;
  font-weight: 600;
  color: var(--green);
}

input:not([type="checkbox"]):not([type="radio"]):not([type="image"]):not([type="range"]),
select {
  padding: 16px;
  margin: 5px 0;
  width: 100%;
  max-width: 100%;
  font-size: 1.2em;
  font-weight: normal;
  color: #404040;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  padding: 16px;
}

input.ng-invalid {
  border-color: red;
}
.message {
  margin: 32px 32px;
  font-size: 1.2em;
}
.message img {
  max-width: 100%;
}
.optionsBox {
  display: flex;
  justify-content: center;
}
.optionsBox div {
  padding: 16px;
}

.column {
  display: flex;
  justify-content: center;
  align-items: center;
}
.column > * {
  flex-grow: 1;
}
.twocolumn > * {
  width: 50%;
}
.divider {
  width: 1px;
  margin: 20px 64px;
  background-color: #e7e7e7;
  height: 200px;
}

.bottom-border {
  border-bottom: 1px dashed #ebecee;
  margin: 20px 0px 20px 0px;
}
.mat-cancel {
  margin-right: 30px;
}
video {
  display: block;
}

.video-container video {
  width: 100%;
}

.local-media-container video {
  width: 95%;
  height: 250px;
  background: #ededed;
  margin: auto;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  border-radius: 6px;
}

.remote-media-container {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  margin: 0;
}

.remote-media-container video {
  transform: scale(-1, 1);
  margin: auto;
  width: 95%;
  height: 250px;
  background: #ededed;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  border-radius: 6px;
}

.participant-name {
  width: 95%;
  margin: 15px auto;
  padding: 7.25px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: var(--light-main);
  background-color: #ededed;
  border-radius: 6px;
  min-height: 44px;
}

.video-disabled-image {
  height: 250px;
  margin: auto;
  border-radius: 6px;
  padding: 75px 0;
  background: #f8f8f8;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  width: 95%;
}

#muted-icon {
  color: var(--ion-color-danger);
  padding: 5px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 35%);
}

.rubrics-popup {
  text-align: start;
  overflow: auto !important;
}

.rubrics-popup .alert-wrapper {
  width: fit-content;
  min-width: 20%;
  max-width: 94%;
}

.rubrics-popup .alert-message {
  height: fit-content;
  min-height: fit-content;
  max-height: 1000px;
}

.rubric-popup-title {
  color: var(--dark-main);
  font-weight: bold;
}

.total-rubric-popup {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--light-main);
}

.tab-label-badge {
  margin: 0 5px;
  min-width: 25px;
  min-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--light-main);

  box-shadow: -2px 2px 4px 1px rgba(89, 87, 87, 0.4);
  -webkit-box-shadow: -2px 2px 4px 1px rgba(89, 87, 87, 0.4);
  -moz-box-shadow: -2px 2px 4px 1px rgba(89, 87, 87, 0.4);
}

.mat-tab-label {
  opacity: 1 !important;
  color: var(--light-main);
  font-weight: 500;
  font-size: 1.25rem;
}

.mat-tab-header {
  position: sticky !important;
  top: 0 !important;
  z-index: 2 !important;
  background: white !important;
  padding-top: 5px !important;
}

.mat-ink-bar {
  background-color: var(--green) !important;
}

.mat-tab-label-active {
  font-weight: 700;
  background: var(--dark-main);
  border-radius: 10px 10px 0px 0px;
  color: white;
}

.gradebook-sidemenu .mat-dialog-container::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
}

.gradebook-sidemenu .mat-dialog-container::-webkit-scrollbar-thumb {
  border-radius: 14px;
  background-color: var(--gradebook-light-blue);
}
@media only screen and (max-width: 1000px) {
  * {
    max-width: 100%;
  }
  #page {
    margin: 10px;
    width: auto;
    min-height: calc(100vh - 444px);
    box-shadow: none;
    -webkit-overflow-scrolling: touch;
  }
  footer {
    flex-direction: column;
    width: auto;
  }
  button {
    white-space: normal;
    padding: 10px 25px;
    font-size: 15px;
  }
  .popup-border {
    border-bottom: 1px dashed var(--light-main);
    margin: 10px 0px 20px 0px;
  }
  .newLines {
    white-space: pre-wrap;
    text-align: left;
  }
  .mat-cancel {
    margin-right: 15px;
  }
  .twocolumn {
    flex-direction: column;
  }
  .twocolumn > * {
    width: 100%;
  }
  .divider {
    width: 80%;
    margin: 32px auto;
    height: 1px;
  }
  .icon img {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .mat-tab-label {
    font-size: 1rem;
  }

  .participant-name {
    font-size: .8rem
  }
}

@media only screen and (max-width: 400px) {
  .icon img {
    width: 30px;
    height: 30px;
  }
}

.gradebook-sidemenu .mat-dialog-container {
  padding: 0 !important;
}

html {
  background: #fff;
  min-height: 100vh;
}

/* iOS Media Recorder overlay and preview */

.custom-recorder-open,
.custom-recorder-open body,
.custom-recorder-open #main-app {
  background: transparent !important;
}

.custom-recorder-open #main-app {
  display: none;
}
#custom-recorder-controls {
  display: none;
}
.custom-recorder-open #custom-recorder-controls {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: transparent !important;
}

.custom-recorder-open #timer {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-shadow: 0 0 10px #000, 0 0 5px #000;
  font-size: 24px;
}

#stop {
  display: none;
}
.custom-recorder-recording #stop {
  display: block;
}
.custom-recorder-recording #record {
  display: none;
}

/* Nav Button */
nav-button {
  margin: auto 0;
}
.nav-button {
  width: 32px;
  height: 23px;
  position: sticky;
  left: 10px;
}

.nav-button-toggle {
  height: 3px;
  width: 100%;
  position: absolute;
  background: var(--light-main);
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  transition: 0.1s all ease-in;
}

.nav-button:before,
.nav-button:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  left: 0;
  background: var(--light-main);
  transition: 0.1s all ease-in;
}

.nav-button:before {
  top: 0;
}

.nav-button:after {
  bottom: 0;
}

.nav-button-close .nav-button-toggle,
.nav-button-back .nav-button-toggle {
  opacity: 0;
}

.nav-button-back:after {
  bottom: 0;
  transform: translateX(-50%) translateY(-4px) rotate(45deg);
  width: 20px;
  left: 50%;
}

.nav-button-back:before {
  top: 0;
  transform: translateX(-50%) translateY(4px) rotate(-45deg);
  width: 20px;
  left: 50%;
}

.nav-button-close:before {
  transform: translateY(10px) rotate(45deg);
}

.nav-button-close:after {
  transform: translateY(-10px) rotate(-45deg);
}

.toolbar-menu-item-divider {
  width: 100%;
  height: 1px;
  background: #e7e7e7;
  margin: 3px auto;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--light-main);
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--dark-main);
}

.grecaptcha-badge {
    top: 20px !important;
}

#contact-support-intercome {
    color: var(--ion-color-primary, #3880ff);
    cursor: pointer;
}

// MAT SLIDER STYLES START
.mat-slider.mat-slider-horizontal .mat-slider-wrapper {
    top: 18px;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
    height: 7px;
    border-radius: 10px
}

.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    background-color: var(--gradebook-light-blue);
}

.mat-slider.mat-slider-horizontal .mat-slider-thumb,
.mat-slider.mat-slider-horizontal .mat-slider-thumb-label {
    background-color: var(--gradebook-light-blue);
}

.mat-slider.mat-slider-horizontal .mat-slider-thumb-label {
    height: 25px;
    width: 25px;
    top: -35px;
}

.mat-slider-thumb-label-text {
    font-size: 11px;
    font-size: bold;
    font-family: "Montserrat";
}

.mat-slider.mat-slider-horizontal .mat-slider-thumb {
    height: 20px;
    width: 20px;
    bottom: -12px;
    right: -12px;
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    background-color: white;
}
// MAT SLIDER STYLES END
