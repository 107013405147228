@font-face {
        font-family: SpecialOSK;
        font-style: normal;
        font-weight: normal;
        src: url("keymanweb-osk.eot");
        src: url("keymanweb-osk.eot?#iefix") format("embedded-opentype"), url("keymanweb-osk.ttf") format("truetype"),
                url("keymanweb-osk.woff") format("woff");
}

/* kmw-key-square applies only to OSK key elements, kmw-key-square-ex applies only to popup key elements */

.phone.kmw-osk-frame {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 144px;
        overflow-y: visible;
        background-color: rgba(0, 0, 0, 0.8);
        -webkit-user-select: none;
}
.phone .kmw-osk-inner-frame {
        margin: 0;
        background: transparent;
        width: 400px;
}
.phone .kmw-key-layer-group {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        margin: 0;
        padding: 1px 0;
        border: none;
        background-color: #999999;
}
.phone .kmw-key-layer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: transparent;
        overflow: hidden;
}
.phone .kmw-key-row {
        position: fixed;
        width: 100%;
        height: 20%;
        margin: 0;
        padding: 0;
        border: none;
        overflow: hidden;
}
.phone .kmw-key-square {
        position: fixed;
        display: inline-block;
        height: 100%;
        max-height: 100%;
        overflow: visible;
        margin: 0 0 0 0;
        z-index: 10000;
        padding: 0 0 0 0;
        background-color: transparent;
        cursor: default;
        border: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.phone .kmw-key-square-ex {
        display: inline-block;
        box-sizing: border-box;
        margin: 0 0 0 5px;
        z-index: 10001;
        padding: 0;
        background-color: transparent;
        cursor: default;
}
.phone .kmw-key {
        display: block;
        position: fixed;
        margin: 0px;
        border-radius: 6px;
        text-align: center;
        box-sizing: border-box;
        overflow: hidden;
        border: solid 2px #999999;
        box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 1) inset;
}

.phone.windows .kmw-key-row {
        max-width: 80%;
}
.phone.windows .kmw-key {
        position: static;
}

.phone .kmw-5rows {
        padding-top: 0;
}
.phone .kmw-key-label {
        position: absolute;
        left: 5%;
        top: 1%;
        font: 0.5em Arial;
        color: #aaa;
        background-color: transparent;
        z-index: 10000;
}

.phone .kmw-key-row {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.phone.ios .kmw-key-layer-group {
        background-color: #cfd3d9;
}
.phone.ios .kmw-key {
        border: none;
        border-bottom: solid 1px #8a8d90;
        box-shadow: none;
        border-radius: 5px;
}
.phone.ios .kmw-key.kmw-key-default {
        color: #152942;
        background-color: #fdfdfe;
}
.phone.ios .kmw-key.kmw-key-shift,
.phone.ios .kmw-key.kmw-key-special {
        color: #fff;
        background-color: #b2b9c5;
}
.phone.ios .kmw-key.kmw-key-shift-on,
.phone.ios .kmw-key.kmw-key-special-on {
        color: #152942;
        background-color: #fdfdfe;
}
.phone.ios .kmw-key.kmw-key-touched {
        background-color: #26ace0;
}

.phone.android .kmw-key-layer-group {
        background-color: #333;
}
.phone.android .kmw-key {
        border: none;
        border-bottom: solid 1px #8a8d90;
        box-shadow: none;
        border-radius: 3px;
}
.phone.android .kmw-key.kmw-key-default {
        color: #fff;
        background-color: #777;
}
.phone.android .kmw-key.kmw-key-shift,
.phone.android .kmw-key.kmw-key-special {
        color: #fff;
        background-color: #555;
}
.phone.android .kmw-key.kmw-key-shift-on,
.phone.android .kmw-key.kmw-key-special-on {
        color: #152942;
        background-color: #ddd;
}
.phone.android .kmw-key.kmw-key-touched {
        background-color: #bbb;
}
.phone.android .kmw-key.kmw-spacebar {
        background-color: #555;
}
.phone.android .kmw-key.kmw-spacebar.kmw-key-touched {
        background-color: #bbb;
}
.phone.android .kmw-spacebar-caption {
        color: #aaa;
}

.tablet.kmw-osk-frame {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 144px;
        overflow-y: visible;
        background-color: rgba(0, 0, 0, 0.8);
        -webkit-user-select: none;
}
.tablet .kmw-osk-inner-frame {
        margin: 0;
        background: transparent;
        width: 400px !important;
}
.tablet .kmw-key-layer-group {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        margin: 0;
        padding: 1px 0;
        border: none;
        background-color: #999999;
}
.tablet .kmw-key-layer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: transparent;
        overflow: hidden;
}
.tablet .kmw-key-row {
        position: fixed;
        width: 100%;
        height: 20%;
        margin: 0;
        padding: 0;
        border: none;
        overflow: hidden;
}
.tablet .kmw-key-square {
        position: fixed;
        display: inline-block;
        height: 100%;
        max-height: 100%;
        overflow: hidden;
        margin: 0 0 0 0;
        z-index: 10000;
        padding: 0 0 0 0;
        background-color: transparent;
        cursor: default;
        border: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.tablet .kmw-key-square-ex {
        display: inline-block;
        box-sizing: border-box;
        margin: 0 0 0 5px;
        z-index: 10001;
        padding: 0;
        background-color: transparent;
        cursor: default;
}
.tablet .kmw-key {
        display: block;
        position: fixed;
        margin: 2px;
        border-radius: 8px;
        text-align: center;
        box-sizing: border-box;
        overflow: visible;
        border: solid 2px #999999;
        box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 1) inset;
}

.tablet .kmw-key-label {
        position: absolute;
        left: 7%;
        top: 2%;
        font: 0.5em Arial;
        color: #aaa;
        background-color: transparent;
        z-index: 10000;
}
.tablet .kmw-key-text {
        position: relative;
        left: 0;
        top: 0;
        -webkit-user-select: none;
}

.tablet.ios .kmw-key-layer-group {
        background-color: #cfd3d9;
}
.tablet.ios .kmw-key {
        border: none;
        border-bottom: solid 1px #8a8d90;
        box-shadow: none;
        border-radius: 5px;
}
.tablet.ios .kmw-key.kmw-key-default {
        color: #152942;
        background-color: #fdfdfe;
}
.tablet.ios .kmw-key.kmw-key-shift,
.tablet.ios .kmw-key.kmw-key-special {
        color: #fff;
        background-color: #b2b9c5;
}
.tablet.ios .kmw-key.kmw-key-shift-on,
.tablet.ios .kmw-key.kmw-key-special-on {
        color: #152942;
        background-color: #fdfdfe;
}
.tablet.ios .kmw-key.kmw-key-touched {
        background-color: #26ace0;
}

.tablet .kmw-key-row {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.tablet.android .kmw-key-layer-group {
        background-color: #b4b4b8;
        border-top: 1px solid #666;
}
.tablet.android .kmw-key {
        border: none;
        border: solid 1px #111;
        box-shadow: 0px 2px 5px #888;
        border-radius: 3px;
}
.tablet.android .kmw-key.kmw-key-default {
        color: #152942;
        background-color: #ddd;
}
.tablet.android .kmw-key.kmw-key-shift,
.tablet.android .kmw-key.kmw-key-special {
        color: #0f6396;
        background-color: #a8a8a8;
}
.tablet.android .kmw-key.kmw-key-shift-on,
.tablet.android .kmw-key.kmw-key-special-on {
        color: #152942;
        background-color: #0f6396;
}
.tablet.android .kmw-key.kmw-key-touched {
        background-color: #777;
}
.tablet.android .kmw-key.kmw-spacebar {
        background-color: #d8d8d8;
}
.tablet.android .kmw-key.kmw-spacebar.kmw-key-touched {
        background-color: #777;
}
.tablet.android .kmw-spacebar-caption {
        color: #888;
}

/* Vertical centering of text labels on keys */
.kmw-key {
        text-align: center;
        white-space: nowrap;
}
.kmw-key:before {
        content: ".";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        max-width: 0px;
        visibility: hidden;
}
.kmw-key span {
        display: inline-block;
        vertical-align: middle;
}

.desktop .kmw-osk-frame {
        position: absolute;
        width: auto;
        height: auto;
        left: 0;
        top: 0;
        display: none;
        margin: 0;
        padding: 0;
        border: 2px solid #26ace0;
        border-radius: 2px;
        background-color: #152942;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
}
.desktop .kmw-osk-inner-frame {
        margin: 0;
        background-color: #152942;
        border: 2px solid #26ace0;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        width: 500px !important;
}
.desktop .kmw-key-layer-group {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: none;
        background: transparent;
}
.desktop .kmw-key-layer {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: transparent;
        overflow: hidden;
}
.desktop .kmw-key-row {
        position: static;
        width: 100%;
        height: 20%;
        margin: 0;
        padding: 0;
        border: none;
        overflow: hidden;
}
.desktop .kmw-key-square {
        position: relative;
        height: 100%;
        display: inline-block;
        float: left;
        margin: 0 0 0 5px;
        z-index: 10000;
        font-size: 0.8em;
        overflow: hidden;
        padding: 1px 0 1px 0;
        background-color: transparent;
        cursor: default;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        border: solid 1px transparent;
}
.desktop .kmw-key {
        display: block;
        margin: 0;
        border-radius: 6px;
        text-align: center;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        overflow: hidden;
        width: 100%;
        height: 100%;
}
.desktop .kmw-key-label {
        position: absolute;
        left: 2px;
        top: 2px;
        font: 0.5em Arial;
        color: #888;
        background-color: transparent;
}

/* Popup icon style (and content)*/
.kmw-key-popup-icon:before {
        content: "\2022";
}

.kmw-key-popup-icon {
        position: absolute;
        display: block;
        visibility: visible;
        right: 4%;
        top: 1%; /*width:8px;height:8px;*/
        font: bold 0.5em Arial;
        color: #aaa;
}
/*.phone .kmw-key-popup-icon{right:6%;top:-2px;width:8px;height:8px;text-align:right;}
.desktop .kmw-key-popup-icon{right:4%;top:0;width:8px;height:8px;} */

.phone.android .kmw-key-popup-icon {
        top: 1px;
        right: 8%;
}
.phone.android .kmw-key-label {
        left: 10%;
        top: 4%;
}

.tablet.android .kmw-key-popup-icon {
        top: 0px;
        right: 5%;
}
.tablet.android .kmw-key-label {
        left: 6%;
        top: 3%;
}

/*  Additional desktop element styles */
.kmw-footer {
        height: 16px;
        width: auto;
        margin: 0;
        padding: 0;
        padding-top: 2px;
        background-color: #26ace0;
        border-radius: 0 0 6px 6px;
}
.kmw-title-bar {
        height: 20px;
        width: auto;
        margin: 0;
        background-color: #26ace0;
        padding: 0;
        border-radius: 6px 6px 0 0;
}
.kmw-title-bar-caption {
        color: white;
        margin: 0 20px 0 4px;
        font: bold 0.8em Arial;
}
.kmw-title-bar-actions {
        position: absolute;
        cursor: default;
        right: 2px;
        top: 0;
        width: 33px;
        height: 13px;
}
.kmw-footer-caption {
        color: #fff;
        font: 0.7em Arial;
        margin: 0 0 0 4px;
}

.kmw-footer-resize {
        cursor: se-resize;
        position: absolute;
        right: 2px;
        bottom: 2px;
        width: 16px;
        height: 16px;
        overflow: hidden;
        font-family: SpecialOSK;
        color: white;
}
.kmw-footer-resize:hover {
        font-weight: bold;
}
.kmw-footer-resize:before {
        content: "\e023";
}

.kmw-title-bar-image {
        cursor: default;
        float: right;
        padding: 2px 2px 0 0;
        width: 16px;
        height: 16px;
        font-family: SpecialOSK;
        color: white;
}
.kmw-title-bar-image:hover {
        font-weight: bold;
}
#kmw-pin-image:before {
        content: "\e024";
}
#kmw-config-image:before {
        content: "\e030";
}
#kmw-help-image:before {
        content: "\e042";
}
#kmw-close-button:before {
        content: "\e025";
}

/* Common key appearance styles (can override with form-factor styles if necessary) */
.kmw-key-default {
        color: #152942;
        background-color: #eee;
}
.kmw-key-shift {
        color: #aaa;
        background-color: #ddd;
} /*color was #26ace0 (blue) */
.kmw-key-shift-on {
        color: #ffffff;
        background-color: rgba(15, 98, 150, 0.6);
}
.kmw-key-deadkey {
        color: #eb445a;
        background-color: #eee;
}
.kmw-key-blank {
        color: #152942;
        background-color: rgba(100, 100, 100, 0.5);
}
.kmw-key-hidden {
        visibility: hidden;
}
.kmw-key-touched {
        background-color: #26ace0;
        color: #FFFFFF;
}

/* Adjust font (image) size on special keys */
.tablet .kmw-key-shift,
.tablet .kmw-key-shift-on {
        font-size: 0.8em !important;
}
.phone .kmw-key-shift,
.phone .kmw-key-shift-on {
        font-size: 0.7em !important;
}

/* Set special font and style for modifier and special key images */
body div.kmw-key-shift span.kmw-key-text {
        font-family: SpecialOSK !important;
        font-size: 1em !important;
}
body div.kmw-key-shift-on span.kmw-key-text {
        font-family: SpecialOSK !important;
        font-size: 1em !important;
}
#kmw-popup-keys div.kmw-key-shift span.kmw-key-text {
        font-family: SpecialOSK !important;
}

/* Style for callout used on phones */
#kmw-popup-callout {
        position: fixed;
        display: block;
        background-color: #fdfdfe;
        border-radius: 0 0 6px 6px;
        z-index: 10001;
        pointer-events: none;
}

/* Key preview styles */
div.ios div.kmw-keytip {
        position: fixed;
        left: 0;
        top: 0;
        width: 3em;
        height: 3em;
        background-color: rgba(0, 0, 0, 0);
        overflow: visible;
}

div.android div.kmw-keytip {
        position: fixed;
        left: 0;
        top: 0;
        width: 3em;
        height: 3em;
        color: #fff;
        background-color: rgba(0, 0, 0, 0);
        overflow: visible;
}

div.android #keytip {
        background-color: #f00;
}

/* Box styles for keyboard-specific OSK (e.g. EuroLatin) and if no keyboard active (desktop only) */
.kmw-osk-static,
.kmw-osk-none {
        text-align: left;
        font: 12px sans-serif;
        border: solid 1px #26ace0;
        color: blue;
        background-color: white;
}
.kmw-osk-none {
        padding: 4px 6px 6px;
}
.kmw-osk-none:before {
        content: "Installing keyboard...";
}

/* OSK language menu styles */
#kmw-language-menu {
        position: absolute;
        left: 0;
        width: 232px;
        max-width: 232px;
        z-index: 10004;
        background-color: rgba(128, 128, 128, 1);
        border: 3px solid #888;
        padding: 0;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        box-shadow: 3px 3px 2px #ccc;
        -webkit-box-shadow: 3px 3px 2px #ccc;
        overflow: hidden;
        -ms-touch-action: none;
        -webkit-user-select: none;
}

#kmw-language-menu * {
        -ms-touch-action: none;
        -webkit-user-select: none;
}

#kmw-language-menu p {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
        border-top: 1px dotted #989898;
        border-bottom: 1px dotted #989898;
        margin: 0;
        padding: 10px 0px 10px 10px;
        overflow: hidden;
        color: #0000ff;
        background-color: transparent;
        white-space: nowrap;
}

#kmw-language-menu div.kbd-list-open p.kbd-list-entry {
        display: block;
}
#kmw-language-menu div.kbd-list-closed p.kbd-list-entry {
        display: none;
}
#kmw-language-menu-background {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: 10003;
}

#kmw-menu-scroll-container {
        position: absolute;
        left: 0;
        top: 0px;
        width: 200px;
        max-width: 200px;
        overflow: auto;
        z-index: 10005;
}
#kmw-menu-scroller {
        position: absolute;
        left: 0;
        top: 0;
        width: 200px;
        max-width: 200px;
        background-color: #e0e0e0;
        overflow: auto;
        overflow-x: hidden;
}

#kmw-menu-index {
        position: absolute;
        right: 0;
        width: 32px;
        font-size: 14px;
}
#kmw-menu-index p {
        text-align: center;
        border: none;
        padding: 0px 2px;
        margin: 0;
        color: white;
}

#kmw-menu-footer {
        height: 2px;
        background-color: #808080;
}

#kmw-language-menu .kbd-list {
        position: relative;
        padding-left: 10px;
        background: transparent;
}
#kmw-language-menu .kbd-single-entry {
        position: relative;
        padding-left: 10px;
        background: transparent;
}
#kmw-language-menu .kbd-list-entry {
        position: relative;
        padding-left: 30px;
        background: transparent;
}
#kmw-language-menu .selected {
        color: white;
        background-color: #c82;
} /*#26ace0;}*/
#kmw-language-menu .current {
        font-weight: bold;
}

/* Popup key styles */
#kmw-popup-keys {
        position: fixed;
        display: block;
        width: auto;
        height: auto;
        overflow-y: visible;
        padding: 4px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #fdfdfe;
        border: none;
        z-index: 10000;
}

.tablet.ios #kmw-popup-keys {
        padding: 4px 7px 0px 0px;
}
.phone.ios #kmw-popup-keys {
        padding: 4px 5px 0px 0px;
}

.tablet.ios #kmw-popup-keys .kmw-key {
        border: none;
}
.phone.ios #kmw-popup-keys .kmw-key {
        border: none;
}

.phone.android #kmw-popup-keys {
        border: none;
        border-radius: 2px;
        background-color: #ccc;
        padding: 5px 5px 0 0;
}
.tablet.android #kmw-popup-keys {
        border: 1px solid #eee;
        border-radius: 3px;
        background-color: #888;
        padding: 8px 12px 4px 4px;
}

/* Filter (shim) to darken screen and highlight popup keys */
#kmw-popup-shim {
        position: fixed;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        display: block;
        opacity: 0.15;
        background-color: #000;
        pointer-events: none;
}

/* Styles for other KMW elements (keyboard loading wait notification, etc.) */
.kmw-wait-background {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        z-index: 11000;
}
.kmw-wait-box {
        width: 240px;
        height: 80px;
        margin-top: 20%;
        margin-left: auto;
        margin-right: auto;
        border: 3px solid #26ace0;
        border-radius: 8px;
        text-align: center;
        padding: 0px;
        background: white;
}
.kmw-alert-close {
        float: right;
        height: 24px;
        width: 24px;
        font: 1em bold Arial, sans-serif;
        color: #26ace0;
}
/*.kmw-alert-close{float:right; height:24px; width:24px; font:2em bold Arial,sans-serif;color:#26ace0;} */
.kmw-alert-close:before {
        content: "\00d7";
}
/*.kmw-alert-close{float:right;background:url('icons.gif') no-repeat -30px 0; height:13px; width:15px;}*/
.kmw-wait-text {
        clear: both;
        margin: 4px;
        white-space: nowrap;
}
.kmw-wait-graphic {
        width: 100%;
        height: 75%;
        background: url("ajax-loader.gif") no-repeat;
        background-position: center top;
}
.kmw-alert-text {
        margin: 10px;
        white-space: default;
        font-family: Arial, sans-serif;
}

.kmw-spacebar-caption {
        font: 0.6em Arial !important;
        color: rgba(0, 0, 0, 0.15);
}

/* Static styles */

.desktop-static.kmw-osk-inner-frame {
        margin: 0;
        background-color: #152942;
        border: 2px solid #26ace0;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        width: 500px !important;
}
.desktop-static .kmw-key-layer-group {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: none;
        background: transparent;
}
.desktop-static .kmw-key-layer {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: transparent;
        overflow: hidden;
}
.desktop-static .kmw-key-row {
        position: static;
        width: 100%;
        height: 20%;
        margin: 0;
        padding: 0;
        border: none;
        overflow: hidden;
}
.desktop-static .kmw-key-square {
        position: relative;
        height: 100%;
        display: inline-block;
        float: left;
        margin: 0 0 0 5px;
        z-index: 10000;
        font-size: 0.8em;
        overflow: hidden;
        padding: 1px 0 1px 0;
        background-color: transparent;
        cursor: default;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        border: solid 1px transparent;
}
.desktop-static .kmw-key {
        display: block;
        margin: 0;
        border-radius: 6px;
        text-align: center;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        overflow: hidden;
        width: 100%;
        height: 100%;
}
.desktop-static .kmw-key-label {
        position: absolute;
        left: 2px;
        top: 2px;
        font: 0.5em Arial;
        color: #888;
        background-color: transparent;
}
.desktop-static .kmw-key-text {
        position: relative;
        left: 2px;
        top: 2px;
}

.phone-static.kmw-osk-inner-frame {
        margin: 0;
        background-color: #666;
        border: 2px solid #26ace0;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        width: 400px !important;
}
.phone-static .kmw-key-layer-group {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: none;
        background: transparent;
}
.phone-static .kmw-key-layer {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: transparent;
        overflow: hidden;
}
.phone-static .kmw-key-row {
        position: relative;
        width: 100%;
        height: 20%;
        margin: 0;
        padding: 0;
        border: none;
        overflow: hidden;
}
.phone-static .kmw-key-square {
        position: absolute;
        height: 100%;
        display: inline-block;
        float: left;
        margin: 0 0 0 5px;
        z-index: 10000;
        font-size: 0.8em;
        overflow: hidden;
        padding: 1px 0 1px 0;
        background-color: transparent;
        cursor: default;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
}
.phone-static .kmw-key {
        display: block;
        margin: 0;
        border-radius: 6px;
        text-align: center;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        overflow: hidden;
        width: 100%;
        height: 100%;
}
.phone-static .kmw-key-label {
        position: absolute;
        left: 2px;
        top: 2px;
        font: 0.5em Arial;
        color: #888;
        background-color: transparent;
}
.phone-static .kmw-key-text {
        position: relative;
        left: 2px;
        top: 2px;
}

.tablet-static.kmw-osk-inner-frame {
        margin: 0;
        background-color: #666;
        border: 2px solid #26ace0;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        width: 400px !important;
}
.tablet-static .kmw-key-layer-group {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: none;
        background: transparent;
}
.tablet-static .kmw-key-layer {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: transparent;
        overflow: hidden;
}
.tablet-static .kmw-key-row {
        position: relative;
        width: 100%;
        height: 20%;
        margin: 0;
        padding: 0;
        border: none;
        overflow: hidden;
}
.tablet-static .kmw-key-square {
        position: absolute;
        height: 100%;
        display: inline-block;
        float: left;
        margin: 0 0 0 5px;
        z-index: 10000;
        font-size: 0.8em;
        overflow: hidden;
        padding: 1px 0 1px 0;
        background-color: transparent;
        cursor: default;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
}
.tablet-static .kmw-key {
        display: block;
        margin: 0;
        border-radius: 6px;
        text-align: center;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        overflow: hidden;
        width: 100%;
        height: 100%;
}
.tablet-static .kmw-key-label {
        position: absolute;
        left: 2px;
        top: 2px;
        font: 0.5em Arial;
        color: #888;
        background-color: transparent;
}
.tablet-static .kmw-key-text {
        position: relative;
        left: 2px;
        top: 2px;
}

.kmw-embedded .ios .kmw-key {
        left: 0 !important;
        bottom: 4px !important;
}

.kmw-embedded .ios .kmw-key-square {
        height: 100% !important;
}

.kmw-embedded .ios.kmw-osk-frame {
        top: 0 !important;
        bottom: initial !important;
        width: 100% !important;
}

.kmw-embedded .ios.kmw-osk-frame {
        position: absolute !important;
}

.kmw-embedded .ios.kmw-osk-frame .kmw-key-layer-group {
        position: absolute !important;
        top: 0 !important;
        bottom: initial !important;
}

.kmw-embedded .ios.kmw-osk-frame .kmw-key-layer {
        position: absolute !important;
        top: 0 !important;
        bottom: initial !important;
}

.kmw-embedded .ios.kmw-osk-frame .kmw-key-square {
        position: absolute !important;
}
.kmw-embedded .ios.kmw-osk-frame .kmw-key-row {
        position: absolute !important;
}
.kmw-embedded .ios.kmw-osk-frame .kmw-key {
        position: absolute !important;
}
